import {_8bitdo_devices_filters, Select_8bitdo_Devices} from "@/CSD/Gamepad_tools";
let g_pid = null;
let [g_usage,g_usagePage] = [null,null];


export function WebHidisSupport(){
    if("hid" in navigator){
        console.log("WebHid is Support");
        return true;
    }else{
        console.log("WebHid is not Support");
        return false;
    }
}

export async function ConnectionHID(){
    let HidDevice = null;
    //选择指定HID设备
    const devices = await navigator.hid.requestDevice({ filters: _8bitdo_devices_filters});
    if(Array.isArray(devices)){
        devices.forEach(device=>{
            g_pid = device.productId.toString(16).toUpperCase();          //记录公有pid
            [g_usage,g_usagePage] = Select_8bitdo_Devices(g_pid);
            //console.log("pid :0x"+ device.productId.toString(16).toUpperCase()+ ";vid :0x"+device.vendorId.toString(16).toUpperCase()+ "productName:"+device.productName);
            //遍历usage列表
            device.collections.forEach(collection=>{
                //console.log("collection.usage: "+collection.usage+"  collection.usagePage: "+collection.usagePage);
                if(collection.usage === g_usage&&collection.usagePage === g_usagePage){
                    HidDevice = device;
                }
            })
        })
        if(HidDevice!=null){                 //有HID设备
            //打开指定的HID设备
            //console.log("HidDevice");
            if(!HidDevice.opened){         //如果设备没有打开
                try{
                    await HidDevice.open()
                        .then(() => {
                            //console.log("open is ok");
                            // 在这里进行数据发送、接收等操作
                        })
                        .catch(error => {
                            console.error('打开设备失败:', error);
                        });
                }catch (error){
                    console.error("open is failed",error);
                }
            }
            else{console.log("设备已经打开");}
        }
        return HidDevice;
    }
/*备份*/
    // try {
    //     //选择指定HID设备
    //     const devices = await navigator.hid.requestDevice({ filters: [{
    //          usage:1, usagePage:65402,
    //          //vendorId:0x2DC8,productId:0x3208,
    //         }] });
    //     if(Array.isArray(devices)){
    //         devices.forEach(device=>{
    //             console.log("pid :0x"+
    //                 device.productId.toString(16).toUpperCase()+
    //                 ";vid :0x"+device.vendorId.toString(16).toUpperCase()+
    //                 "productName:"+device.productName
    //             );
    //             // GamepadPID_VID.pid = device.productId;                          //存储起来，用于更新完成后，使用代码打开hid设备，StdAfx.js中定义，已注释掉
    //             // GamepadPID_VID.vid = device.vendorId;
    //             HidDevice = device;
    //         })
    //         //打开指定的HID设备
    //         if(HidDevice!=null){                 //有HID设备
    //             //打开指定的HID设备
    //             if(!HidDevice.Isopened){         //如果设备没有打开
    //                 try{
    //                     await HidDevice.open();
    //                     console.log("open is ok");
    //                 }catch (error){
    //                     console.error("open is failed",error);
    //                 }
    //             }
    //             else{console.log("设备已经打开");}
    //         }
    //         return HidDevice;
    //     }
    // } catch (error) {
    //     console.error('请求 HID 设备时出错:', error);
    // }
}

//数据发送
export async function SendReport(device,reportId,data){
    if(typeof reportId ==='number' && data instanceof Uint8Array){
        await device.sendReport(reportId, data)
            .then(() => {
                //通过判断是否有接收即可知道是否发送成功
                //console.log("发送成功");
            })
            .catch((error) => {
                console.error("发送报告时出错:", error);
            })
    }else{
        console.log("SendReport参数错误");
    }
}

//非异步数据接收，调用一次获取一次数据，使用数组变量接收
export async function ReceiveReport(device){                    //这里的接收是不会包含reportID的
    return new Promise((resolve, reject) => {
        device.oninputreport = (event) => {
            let data = new DataView(event.data.buffer);
            //console.log(`Received report:\n${new Uint8Array(data.buffer).toString()}`);
            //let data = new Uint8Array(event.data.buffer);
            //console.log(`Received report:\n${data}`);
            resolve(data); // 将接收到的数据传递给 Promise 的解析函数,相当于return
            device.oninputreport = null;
        }
    });

}



/*----------------------------------------------------------保留功能----------------------------------------------------------*/
//异步接收
// export async function ReceiveReport(device){
//     device.oninputreport = (event)=>{
//         let data = new Uint8Array(event.data.buffer);
//         console.log(`Received report:\n${data}`);
//         return data;
//     }
// }

//热插拔,直接在vue中编写
// eslint-disable-next-line no-unused-vars
// var HidBase_HidDeviceStatus = null;
// // eslint-disable-next-line no-unused-vars
// var HidBase_device;
//
// export function HidConnectStatus(){
//     navigator.hid.onconnect = ()=>{
//         HidBase_HidDeviceStatus = "有HID设备连接，请点击 连接HID 选择HID设备"
//     }
//     navigator.hid.ondisconnect = () => {
//         HidBase_HidDeviceStatus = "HID设备未连接"
//         HidBase_device = null;
//     };
// }
