import {
    BOOT_CMD_PACKET,
    boot_crc_list, BOOT_ENCRYPT_WRITE_FLASH_CMD,
    BOOT_ERASE_FLASH_CMD,
    BOOT_FLASH_CRC_CMD, BOOT_FLASH_SAVE_CMD,
    BOOT_GET_DEVICE_PID,
    boot_header_t,
    boot_header_t_size,
    BOOT_INIT_DEVICE_CMD,
    BOOT_READ_DATA_CMD, BOOT_RESET_CMD,
    BOOT_REV_REPORT_ID,
    CY_PRO_2_APP_VERSION_FLASH_ADDRESS,
    FIRMWARE_HHEADER_T, flash_info,
    Sizeof_boot_header_t,
    Sizeof_FIRMWARE_HHEADER_T,
    sizeofBoot_crc_list, sizeofFlash_info
} from '@/CSD/StdAfx'
import {SendReport, ReceiveReport, ConnectionHID} from "@/HidService/HID_Base";
import {GetSelect, PutFullDownload} from "@/api/api";
import { ElNotification } from 'element-plus';

let Datbuffer           //存储dat数据

/*---------------------------------------------------修改完成---------------------------------------------------*/
//下载固件,存储至Datbuffer
export async function downloadFile(datName){
    let url = await PutFullDownload({fileName: datName});
    //let url = "https://tools.zackxu.com:8083/firmwareFile/upload/"+datName;
    //let url = "https://tools.zackxu.com:8083/firmwareFile/upload/"+datName;
    //let url = "http://120.78.9.22:8083/firmwareFile/upload/"+datName;                 //测试云120.78.9.22
    //let url = "http://localhost:8081/firmwareFile/upload/"+datName;                   //本地
    //let url = "http://beta.8bitdo.com:8080/firmwareFile/upload/"+datName;
    //2024/10/15/本地测试使用：  let download_url = 'https://'+url.data;              //应该是axios依赖升级的原因导致每次都会在前面自动添加http://localhost:8084....,被当作了不完整的相对路径
    console.log(url);                                                    //打印下载地址
    
    return fetch(url.data)          //url.data
        .then(response =>{
            if(!response.ok){
                throw new Error('Network response was not ok');
            }else{
                return response.arrayBuffer();                      //以数组形式返回
            }
        })
        .then(arrayBuffer=>{
            Datbuffer = new Uint8Array(arrayBuffer);                //获取到的数据存储至Datbuffer
            //console.log(Datbuffer);
        })
        .catch(error => {
                console.error('Error downloading file:', error);
                throw error;
            });
}
export function Console(){
    console.log(Datbuffer);
}

//读取dat头数据
export async function GetDatcontent(){
    //console.log(Datbuffer.buffer);
    const data = new DataView(Datbuffer.buffer);
    FIRMWARE_HHEADER_T.version = data.getUint32(0, true)         //64
    FIRMWARE_HHEADER_T.desAddress = data.getUint32(4, true)      //6000
    FIRMWARE_HHEADER_T.desLen = data.getUint32(8, true)          //12000
    FIRMWARE_HHEADER_T.pid = data.getUint32(12, true)            //PID:310B
    FIRMWARE_HHEADER_T.reserved1 = data.getUint32(16, true)      //B459 0A7F？
    FIRMWARE_HHEADER_T.revision = data.getUint32(20, true)
    FIRMWARE_HHEADER_T.reserved2 = data.getUint32(24, true)
    console.log(FIRMWARE_HHEADER_T);
    return FIRMWARE_HHEADER_T

}

/*
读取手柄pid：
.vue用法：
    let pid = ReadGamePadPid(this.device);
    console.log("pid is :"+pid.toString(16).toUpperCase());
*/
export async function ReadGamePadPid(device){
    boot_header_t.cmd = BOOT_GET_DEVICE_PID;
    boot_header_t.cmd_params = 0;
    boot_header_t.len = 0;
    boot_header_t.offset = 0;
    let receivebuffer = await Generate_BootCmdPacket(device,boot_header_t,null)         //receivebuffer 是 头数据后面的有效数据内容
    if(receivebuffer){
        let pid =  receivebuffer[1]<<8 | receivebuffer[0];
        return pid;
        //console.log("pid:",pid.toString(16).toUpperCase());
    }else{
        console.log("ReadGamePadPid error");
    }
}


//新版更新发送命令包操作,附带接收,直接返回有效数据,相比于C++代码中，参数少一个readDate，使用return的方式将接收到的数据返回
export async function Generate_BootCmdPacket(device,gamepad_header_t,src){
//发送数据处理，
    let buffer = new Uint8Array(63);
    buffer[0] = BOOT_CMD_PACKET;                                                                            // reportId = 0x81;buffer[0] = 0x05
    let headerView = new DataView(buffer.buffer,1);                                               //偏移索引从1开始给buffer填充内容;以下注释是占用字节索引
    headerView.setUint16(0,gamepad_header_t.cmd,true);                                  //0-2
    headerView.setUint16(2, gamepad_header_t.cmd_params, true); // cmd_params           //2-4
    headerView.setUint16(4,gamepad_header_t.len,true);                                  //4-6
    headerView.setUint32(8,gamepad_header_t.total_len,true);                           //8-12
    headerView.setUint32(12,gamepad_header_t.offset,true);                             //12-16
/*    headerView.setUint16(0,gamepad_header_t.cmd,true);                                  //0-2
    headerView.setUint16(2, gamepad_header_t.cmd_params, true); // cmd_params           //2-4
    headerView.setUint32(4,gamepad_header_t.offset,true);                               //4-8
    headerView.setUint16(8,gamepad_header_t.len,true);                                  //8-10
    headerView.setUint32(10,gamepad_header_t.total_len,true);                           //10-14
    gamepad_header_t.crc = crc16_Pro2(buffer+boot_header_t+2,gamepad_header_t.len)
    headerView.setUint16(14,gamepad_header_t.crc,true);                                 //14-16*/
    if(src!=null){
        buffer.set(src,boot_header_t_size+1)          //将src数据赋值到buffer的头数据后面,从第19个开始；boot_header_t_size+2 = 18;  但实际实操后发现应该+1
        // eslint-disable-next-line no-empty
    }
    // console.log("Generate_BootCmdPacket():"+buffer[Sizeof_boot_header_t+1]);
    //console.log("gamepad_header_t.len:is "+gamepad_header_t.len);
    let offsetBuffer = buffer.subarray(Sizeof_boot_header_t+1);
    gamepad_header_t.crc = crc16_Pro2(offsetBuffer,gamepad_header_t.len)

    headerView.setUint16(6,gamepad_header_t.crc,true);                                 //6-8

    await SendReport(device,0x81,buffer);                                                          //非boot模式下reportId是0x81，boot模式下是00可以写成.0，系统默认添加

//接收数据处理
    let dataview = await ReceiveReport(device);                 //用于按字节位数存储数据至结构体，这里接收到的数据不会包含reportID，所以是剔除了第一位的数据
    let usb_rev_packet = new Uint8Array(dataview.buffer);       //用于获取接收数组，
    //console.log("usb_rev_packet: ",usb_rev_packet);
    if(usb_rev_packet[0] === BOOT_CMD_PACKET){                                                               //05
        boot_header_t.cmd =         dataview.getUint16(1,true);                                             //1-3
        boot_header_t.cmd_params =  dataview.getUint16(3,true);                                             //3-5
        boot_header_t.len =         dataview.getUint16(5,true);                                             //5-7
        boot_header_t.crc =         dataview.getUint16(7,true);                                             //7-9
        boot_header_t.total_len =   dataview.getUint32(9,true);                                             //9-13
        boot_header_t.offset =      dataview.getUint32(13,true);                                            //13-17
        if(boot_header_t.cmd === 0 && boot_header_t.cmd_params  === gamepad_header_t.cmd_params){
            // let data = usb_rev_packet.subarray(Sizeof_boot_header_t+2);                                     //将数据中头数据后的有效数据返回
            // console.log(data);
            return usb_rev_packet.subarray(Sizeof_boot_header_t+1);                                         //返回usb_rev_packet 偏移 Sizeof_boot_header_t+2的数据，即有效数据
        }
    }else{
        console.log("usb_rev_packet[0] == BOOT_CMD_PACKET error");
        return false;
    }
}

//crc校验，
export function crc16_Pro2(pD,len){
    //console.log("pD："+pD);
    let poly = [0,0xA001];
    let crc = 0xffff;
    let index = 0;
    for (let j = len; j > 0; j--) {
        let ds = pD[index];//shift()移除数组中第一个元素并返回，即第一个值赋值给ds
        //console.log("ds:"+ds.toString(16).toUpperCase());
        index++;
        for (let i = 0; i < 8; i++) {
            crc = (crc >> 1) ^ poly[(crc ^ ds) & 1];
            //console.log("crc:"+crc.toString(16).toUpperCase());
            ds >>= 1;
        }
    }
    return crc & 0xFFFF;
}

//读取手柄固件版本
export async function ReadGame_PadFlashVersion(device){
    let Buffer = new Uint8Array(63);
    Buffer[0] = 0x05;
    Buffer[2] = 0x21;
    Buffer[3] = 0x01;
    SendReport(device,0x81,Buffer);

    let dataview = await ReceiveReport(device);                 //用于按字节位数存储数据至结构体，这里接收到的数据不会包含reportID，所以是剔除了第一位的数据
    let usb_rev_packet = new Uint8Array(dataview.buffer);       //用于获取接收数组，
    if(usb_rev_packet[0] == 0x22){
        //console.log(usb_rev_packet);
        let version = usb_rev_packet[4]<<24|usb_rev_packet[3]<<16<<usb_rev_packet[2]<<8|usb_rev_packet[1];
        return version;
    }
}

//弹窗
export function showNotification(tittle,message,type){
    ElNotification({
        title: tittle,
        message: message,
        type: type,
    });
}

/*
* 更新函数
* 初始化设备，boot
* 擦除、写入
*/
export async function StartUpdata(device,FIRMWARE_HHEADER_T,progressval){//
    //初始化设备
    if(await initDevice(device)){           //初始化成功后再操作
        let file_offset = 0;
        let writeLength = 0x1000;
        //let isCRC = await readCRC(device,file_offset,writeLength);

        while(file_offset<FIRMWARE_HHEADER_T.desLen) {
            if((FIRMWARE_HHEADER_T.desLen-file_offset)<writeLength){
                writeLength = FIRMWARE_HHEADER_T.desLen-file_offset;
            }

            let isCRC = await readCRC(device,file_offset,writeLength);
            if(!isCRC){
                //console.log("CRC不一致，重新写入");
                if(await eraseFlash(device,file_offset)){
                    //擦除成功时
                    console.log(`StartUpdate:file_offset:${file_offset},writeLength:${writeLength}`)
                    if(await writeFirmware(device,file_offset,writeLength)){
                        file_offset += writeLength;
                    }
                }
            }else{
                //console.log("CRC一致，跳过写入");
                file_offset += writeLength;
            }
            //这里进度条显示；
            progressval.value = (file_offset*100/FIRMWARE_HHEADER_T.desLen)%101;
            console.log(`progressval:${progressval.value}`);
            if(progressval.value>=100){
                progressval.value = 100;
            }
        }
        //全部 FLASH CRC 验证
        file_offset = 0;
        writeLength = 0x1000;
        while(file_offset<FIRMWARE_HHEADER_T.desLen) {
            if ((FIRMWARE_HHEADER_T.desLen - file_offset) < writeLength) {
                writeLength = FIRMWARE_HHEADER_T.desLen - file_offset;
            }
            let isCRC = await readCRC(device,file_offset,writeLength);
            if(!isCRC) {
                console.log(`CRC校验错误、file_offset: ${file_offset};writeLength: ${writeLength};isCRC:${isCRC}`);
            }else{
                file_offset += writeLength;
            }
        }
        if(await saveColdeBlock(device,FIRMWARE_HHEADER_T)){
            //console.log("save ok!");
            reset(device);
        }

        //再读手柄的固件版本
        //连接上后直接读取固件版本，因为reset方法断开了手柄设备的连接，所以这部分无法实现，除非使用代码重新打开hid设备后才能进行读取操作
        // let gamepad_flash_original_version = await ReadGame_PadFlashVersion(this.device);
        // let gamepad_flash_main_version = (gamepad_flash_original_version & 0xffff) / 100;      //低16位
        // let gamepad_flash_beta_version = (gamepad_flash_original_version>>16) & 0xffff;
        // console.log(`main_version:${this.gamepad_flash_main_version};beta_version:${this.gamepad_flash_beta_version}`)
        console.log("更新完成,重新连接设备");
        showNotification('Finished','更新完成','success');
        //vm.ProgressVal = 0;
    }
}

//初始化设备
export async function initDevice(device){
    boot_header_t.cmd = BOOT_INIT_DEVICE_CMD;
    boot_header_t.cmd_params = 0;
    boot_header_t.len = 0;
    boot_header_t.total_len = 0;
    boot_header_t.offset = 0;
    if(await Generate_BootCmdPacket(device,boot_header_t,null)){
        //console.log("初始化成功");
        return true;
    }else{
        console.log("初始化失败")
        return false;
    }
}

/*读CRC校验
设备句柄，file:dat文件，文件偏移，写入长度
export async function readCRC(device,file,file_offset,file_length){
    console.log("读取CRC校验");
    let buffer = new Uint8Array(12);
    let Buffer = new DataView(buffer.buffer);
    boot_crc_list.address = file_offset;
    Buffer.setUint32(0,boot_crc_list.address,true);     //0-4
    boot_crc_list.len = file_length;                                        //传参：0x1000
    Buffer.setUint32(4,boot_crc_list.len,true);         //4-8
    //console.log("buffer:"+buffer);                                          //0,0,0,0,0,0x10,0,0,0,0,0,0（cc,cc,cc,cc）

    //这部分是命令头，81，05是在发送函数中已经默认有
    boot_header_t.cmd = BOOT_FLASH_CRC_CMD;                                 //81 05 C3
    boot_header_t.cmd_params = 0;
    boot_header_t.len = sizeofBoot_crc_list;                                //0c
    boot_header_t.total_len = 0;
    boot_header_t.offset = 0;

    let r_buffer = new Uint8Array(64);
    r_buffer = await Generate_BootCmdPacket(device,boot_header_t,buffer);
    console.log("r_buffer:"+r_buffer);
    if(r_buffer){
        let flash_crc =  (r_buffer[1]<<8)|r_buffer[0];
        let file_crc = await readFile_CRC(file,file_offset,file_length);
        console.log("flash_crc is :"+flash_crc);
        console.log("file_crc is :"+file_crc);
        if(flash_crc === file_crc){
            return true;
        }
    }
    console.log("flash_crc != file_crc");
    return false;
}

读文件CRC
export async function readFile_CRC(file,file_offset,file_length){
    return new Promise((resolve,reject)=>{
        const reader = new FileReader();                //reader用于读取文件内容
        reader.readAsArrayBuffer(file);                 // 以 ArrayBuffer 格式读取文件
        //文件加载完时执行的回调函数
        reader.onload = function (event){
            let arrayBuffer = event.target.result;      //读取完整内容
            //根据偏移量和长度，将指定数组存储到字数组中
            let offsetBuffer = new Uint8Array(arrayBuffer,Sizeof_FIRMWARE_HHEADER_T+file_offset,file_length);     //28+0,4096
            let crc = crc16_Pro2(offsetBuffer,file_length);
            resolve(crc);                               //resolve(crc) 将会将 crc 作为 Promise 的返回值
        }
        // 文件加载失败时执行的回调函数
        reader.onerror = function(event) {
            reject(event.target.error); // 返回错误信息
        };
    })
}



//写入
export async function writeFirmware(device,file_offset,writeLength,file){            //writeLength：要写入的总长度
    let firmware_4K_length = 0;
    let len = 46;                                       //每次写入46
    while(firmware_4K_length<writeLength){              //如果最后一部分的数据小于46，那么最后一次写入的长度len计算如下
        console.log("firmware_4K_length:"+firmware_4K_length);
        if((writeLength-firmware_4K_length)<len){
            len = (writeLength-firmware_4K_length);
        }
        boot_header_t.cmd = BOOT_ENCRYPT_WRITE_FLASH_CMD;                   //3
        boot_header_t.cmd_params = 0;
        boot_header_t.len = len;
        //console.log(boot_header_t.len );      //打印结果：46
        boot_header_t.total_len = len;
        boot_header_t.offset = file_offset+firmware_4K_length;
        let buffer = await readFile_buffer(file,boot_header_t.offset,len);//读dat文件内容

        if(await Generate_BootCmdPacket(device,boot_header_t,buffer)){
            firmware_4K_length+=len;
            console.log("写入4k成功")
        }
        else {
            console.log("写入失败");
            return false;
        }
    }
    return true;
}

后续优化为只读取一次完整文件，然后偏移后赋值给新的数组，
export async function readFile_buffer(file,file_offset,file_len){
    return new Promise((resolve,reject)=>{
        const reader = new FileReader();
        reader.readAsArrayBuffer(file);

        reader.onload = function (event){
            let arrayBuffer = event.target.result;      //读完整内容
            let offsetBuffer = new Uint8Array(arrayBuffer,Sizeof_FIRMWARE_HHEADER_T+file_offset,file_len);
            //console.log(offsetBuffer);
            resolve(offsetBuffer);
        }
        reader.onerror = function (event){
            reject(event.target.error);
        }
    })
}*/
//保存：写入后保存至手柄固件
export async function saveColdeBlock(device,firmware){
    console.log("save");
    let buffer = new Uint8Array(8);
    let Buffer = new DataView(buffer.buffer);
    flash_info.file_version = firmware.version;
    flash_info.flie_length = firmware.desLen;
    Buffer.setUint32(0,flash_info.file_version,true);
    Buffer.setUint32(0,flash_info.flie_length,true);

    boot_header_t.cmd = BOOT_FLASH_SAVE_CMD;
    boot_header_t.cmd_params = 0;
    boot_header_t.len = sizeofFlash_info;           //8
    boot_header_t.total_len = sizeofFlash_info;
    boot_header_t.offset = 0;

    let result = await Generate_BootCmdPacket(device,boot_header_t,buffer);
    if(result){
        return true;
    }
    return false;
}
//重置手柄
export async function reset(device){
    boot_header_t.cmd = BOOT_RESET_CMD;             //7
    boot_header_t.cmd_params = 0;
    boot_header_t.len = sizeofFlash_info;           //8
    boot_header_t.total_len = sizeofFlash_info;
    boot_header_t.offset = 0;
    let result = await Generate_BootCmdPacket(device,boot_header_t,null);
    if(result){
        return true;
    }
    return false;
}
/*------------------------------------------修改版本------------------------------------------*/
//读CRC，包含手柄和dat文件的CRC
export async function readCRC(device,file_offset,file_length){
    // console.log("读取CRC校验");
    let buffer = new Uint8Array(12);
    let Buffer = new DataView(buffer.buffer);
    boot_crc_list.address = file_offset;
    Buffer.setUint32(0,boot_crc_list.address,true);     //0-4
    boot_crc_list.len = file_length;                                        //传参：0x1000
    Buffer.setUint32(4,boot_crc_list.len,true);         //4-8
    //console.log("buffer:"+buffer);                                          //0,0,0,0,0,0x10,0,0,0,0,0,0（cc,cc,cc,cc）

    //这部分是命令头，81，05是在发送函数中已经默认有
    boot_header_t.cmd = BOOT_FLASH_CRC_CMD;                                 //81 05 C3
    boot_header_t.cmd_params = 0;
    boot_header_t.len = sizeofBoot_crc_list;                                //0c
    boot_header_t.total_len = 0;
    boot_header_t.offset = 0;

    let r_buffer = new Uint8Array(64);
    r_buffer = await Generate_BootCmdPacket(device,boot_header_t,buffer);
    //console.log("r_buffer:"+r_buffer);
    if(r_buffer){
        let flash_crc =  (r_buffer[1]<<8)|r_buffer[0];
        let file_crc = await readFile_CRC(file_offset,file_length);
        // console.log("flash_crc is :"+flash_crc);
        // console.log("file_crc is :"+file_crc);
        if(flash_crc === file_crc){
            return true;
        }
    }
    //console.log("flash_crc != file_crc");
    return false;
}
//读Dat文件中的CRC
export async function readFile_CRC(file_offset,file_length){
    let offsetBuffer = Datbuffer.subarray(Sizeof_FIRMWARE_HHEADER_T+file_offset,Sizeof_FIRMWARE_HHEADER_T+file_offset+file_length);

    //let offsetBuffer = new Uint8Array(Datbuffer,Sizeof_FIRMWARE_HHEADER_T+file_offset,file_length);     //28+0,4096
    //console.log(offsetBuffer);
    let crc = crc16_Pro2(offsetBuffer,file_length);
    return crc;
}

//默认擦除4K操作
export async function eraseFlash(device,address){
    boot_header_t.cmd = BOOT_ERASE_FLASH_CMD;
    boot_header_t.cmd_params = 0;
    boot_header_t.len = 0;
    boot_header_t.total_len = 0;
    boot_header_t.offset = address;
    if(await Generate_BootCmdPacket(device,boot_header_t,null)){
        //console.log("擦除成功");
        return true;
    }else{
        console.log("擦除失败");
        return false;
    }
}
//写入
export async function writeFirmware(device,file_offset,writeLength){            //writeLength：要写入的总长度
    let firmware_4K_length = 0;
    let len = 46;                                       //每次写入46
    while(firmware_4K_length<writeLength){              //如果最后一部分的数据小于46，那么最后一次写入的长度len计算如下
        //console.log("firmware_4K_length:"+firmware_4K_length);
        if((writeLength-firmware_4K_length)<len){
            len = (writeLength-firmware_4K_length);
        }
        boot_header_t.cmd = BOOT_ENCRYPT_WRITE_FLASH_CMD;                   //3
        boot_header_t.cmd_params = 0;
        boot_header_t.len = len;
        //console.log(boot_header_t.len );      //打印结果：46
        boot_header_t.total_len = len;
        boot_header_t.offset = file_offset+firmware_4K_length;
        let buffer = await readFile_buffer(boot_header_t.offset,len);//读dat文件内容
        if(await Generate_BootCmdPacket(device,boot_header_t,buffer)){
            firmware_4K_length+=len;
            //console.log("写入4k成功")
        }
        else {
            console.log("写入失败");
            return false;
        }
    }
    return true;
}
//读取Dat文件中的数据
export async function readFile_buffer(file_offset,file_len){
    //console.log("Sizeof_FIRMWARE_HHEADER_T+file_offset"+Sizeof_FIRMWARE_HHEADER_T+file_offset)
    //console.log("file_len"+file_len)
    //console.log(Datbuffer)
    let offsetBuffer = Datbuffer.subarray(Sizeof_FIRMWARE_HHEADER_T+file_offset,Sizeof_FIRMWARE_HHEADER_T+file_offset+file_len);
    //let offsetBuffer = new Uint8Array(Datbuffer,Sizeof_FIRMWARE_HHEADER_T+file_offset,file_len);
    //console.log(offsetBuffer);
    return offsetBuffer;
}


/*------------------------------------------------------------------------待修改------------------------------------------------------------------------*/


//version来接收，
// export async function ReadGame_PadFlashVersion(device,len,address){
//     console.log("BootReadFlssh address:"+address);
//     Generate_BootCmdPacket1(device,null,len,address,len,BOOT_READ_DATA_CMD,0);
//
//     let dataview = await ReceiveReport(device);                 //用于按字节位数存储数据至结构体
//     let usb_rev_packet = new Uint8Array(dataview.buffer);       //用于获取接收数组，
//     //console.log(usb_rev_packet);
//
//     if(usb_rev_packet[0] == BOOT_REV_REPORT_ID && usb_rev_packet[1] == BOOT_CMD_PACKET){
//         boot_header_t.cmd =         dataview.getUint16(2,true);
//         boot_header_t.cmd_params =  dataview.getUint16(6,true);
//         boot_header_t.len =         dataview.getUint32(10,true);
//         boot_header_t.total_len =   dataview.getUint32(18,true);
//         boot_header_t.offset =      dataview.getUint32(26,true);
//         //version是根据len的长度，来判断是需要几位的，目前是4位
//         let version = usb_rev_packet[21]<<24|
//                     usb_rev_packet[20]<<16|
//                     usb_rev_packet[19]<<8|
//                     usb_rev_packet[18]
//         console.log(boot_header_t);
//         return version;
//     }else{
//         console.log("usb_rev_packet[0] == BOOT_REV_REPORT_ID && usb_rev_packet[1] == BOOT_CMD_PACKET error");
//     }
//
// }

//发送命令包
// export function Generate_BootCmdPacket1(device,src,len,offset,total_len,cmd,cmd_params){
//     let buffer = new Uint8Array(63);
//     buffer[0] = BOOT_CMD_PACKET;
//
//     if(len>64-boot_header_t_size){
//         len = 64-boot_header_t_size;
//     }
//     let headerView = new DataView(buffer.buffer);                                       //偏移索引从1开始给buffer填充内容;,
//     headerView.setUint16(1,cmd,true);                               //true:小端，fasle大端
//     headerView.setUint16(3, cmd_params, true); // cmd_params
//     headerView.setUint32(5, offset, true); // offset
//     headerView.setUint32(9, len, true); // len
//     headerView.setUint32(13, total_len, true); // total_len
//
//     //console.log(BOOT_CMD_PACKET);
//     if(src){
//         buffer.set(src,boot_header_t_size)          //将src数据赋值到buffer后面
//         // eslint-disable-next-line no-empty
//     }else{}
//     console.log("Generate_BootCmdPacket():"+buffer);
//     SendReport(device,0x81,buffer);
// }

// //获取固件版本，命令顺序有变
// export function Generate_BootCmdPacket1(device,src,len,offset,total_len,cmd,cmd_params){
//     let buffer = new Uint8Array(64);
//     buffer[0] = BOOT_CMD_PACKET;
//
//     if(len>64-boot_header_t_size){
//         len = 64-boot_header_t_size;
//     }
//     let headerView = new DataView(buffer.buffer);                                       //偏移索引从1开始给buffer填充内容;,
//     headerView.setUint16(1,cmd,true);                               //true:小端，fasle大端
//     headerView.setUint16(3, cmd_params, true); // cmd_params
//     headerView.setUint32(5, total_len, true); // offset
//     headerView.setUint32(9, len, true); // len
//     headerView.setUint32(13, offset, true); // total_len
//
//     //console.log(BOOT_CMD_PACKET);
//     if(src){
//         buffer.set(src,boot_header_t_size)          //将src数据赋值到buffer后面
//         // eslint-disable-next-line no-empty
//     }else{}
//     console.log("Generate_BootCmdPacket():"+buffer);
//     SendReport(device,.0,buffer);
// }
