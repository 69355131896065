<template>
  <Ultimate_2C_Wireless_Controller/>
</template>

<script>

//import HidDevice_Base from "@/components/HidDevice_Base.vue"
import Ultimate_2C_Wireless_Controller from "@/components/Ultimate_2C_Wireless_Controller.vue"

export default {
  name: 'App',
  components: {
    //HidDevice_Base
    Ultimate_2C_Wireless_Controller
  }
}
</script>

<style>
#app {

}
</style>
