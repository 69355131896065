export function Select_8bitdo_Devices(pid){
    for (let i = 0; i < _8bitdo_devices.length; i++) {
        const device = _8bitdo_devices[i];
        if (pid === device.Pid) {
            return [device.Usage, device.UsagePage];
        }
    }
    return [null, null];// 如果未找到匹配的设备，可以返回一个默认值或者抛出错误
}
export const _8bitdo_devices_filters = [
    {vendorId:0x2DC8,productId:0x310A},     //8BitDo Ultimate 2C Wireless Controller
    {vendorId:0x2DC8,productId:0x301C},     //IDLE
]

export const _8bitdo_devices = [
    {
        Pid:"310A",
        Usage:1,
        UsagePage:65402
    },
    {
        Pid:"301C",
        Usage:1,
        UsagePage:65440,
    }
]




