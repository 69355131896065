<script>
import {ref,reactive,onBeforeMount,onMounted} from 'vue'
import { ElNotification,ElMessage} from 'element-plus';				//ElNotification，element-plus 引入放在datUpdata.js中

import {
		ConnectionHID,
		WebHidisSupport
	} from "@/HidService/HID_Base";
	import {
		GetSelect,
		GetType,
		PutUserAgent
	} from "@/api/api";
	import {
		downloadFile,
		GetDatcontent,
		ReadGame_PadFlashVersion,
		ReadGamePadPid,
		StartUpdata,
		showNotification,
	} from "@/HidService/datUpdata";


export default {
    name: "Ultimate_2C_Wireless_Controller",
    setup() {
//---------------------------------------------------data---------------------------定义变量/对象--------替代data(){}----  -
        const device = ref(null);                          //HID设备句柄
        const gamepad_PID = ref(null);                          //手柄pid
        const Firmware_list = ref([]);                          //固件列表
        const filePathName = ref(null);                         //文件路径名
        const DownloadName = ref(null);                         //下载文件名(dat)
        const firmware_header = ref(null);                      //固件头
        const dat_PID = ref(null);                              //dat文件pid
        const id = ref(null);                                   //id记录，用于修改用户画像
//---------------------------------------------------UI-----------------------------------------------------------
        const Update = ref("请打开HID设备和dat更新文件");         //更新提示
        const gamepad_flash_main_version = ref(null);           //固件主版本号
        const gamepad_flash_beta_version = ref(null);           //固件子版本号(beta版)
        const ProgressVal = ref(0);                           //进度条值
    //按键使能
        const ConnectButton_Flag = ref(true);			//ref(false);                  //连接按键使能
    //页面显示
        const showPage1 = ref(true); //显示界面1
        const showPage2 = ref(false); //显示界面2   
        const showPage3 = ref(false); //显示界面3  
        const btnbox = ref("btn-box2");
    //弹窗
        const Version = ref(null);                              //
        const dialogLog = ref([]);                              //对应readme
        const dialogVisible = ref(false);                       
    //FAQ弹窗
        const activeName = ref('first');
        const FaqVisible = ref(false);
    //列表项1默认展开0-2
		const activeNames = ref(['1']);
    //允许更新 flag
        const updateflag = ref(true);
        const progressvalflag = ref(false);
    //固件索引
        const radio = ref(-1);
    //一键赋值内容
        const linuxCommand1 = ref( `
						cat <<EOF
						# Controller
						KERNEL=="hidraw*", ACTION=="add", ATTR{manufacturer}=="8BitDo", SUBSYSTEM=="hidraw", ATTRS{idVendor}=="2dc8", MODE="0666"
						SUBSYSTEMS=="usb", ATTRS{idVendor}=="2dc8", ATTRS{idProduct}=="310a|6100", MODE="0660", GROUP="plugdev", TAG+="uaccess"
						} | sudo tee /etc/udev/rules.d/8-bitdo-controller.rules
					`.trim());
        const linuxCommand2 = ref(`sudo udevadm control --reload-rules && sudo udevadm trigger`);
            
//---------------------------------------------------onBeforeMount------------------初始化操作------------替代vue2的created--------
		onMounted(() => {
            //初始化操作
            if (!WebHidisSupport()) {
                showPage1.value = false;
				showPage2.value = false;
				showPage3.value = true;
            }else {
				navigator.hid.addEventListener("connect",(event)=>{
					console.log("HID connected: ", event.device);
                    //btnbox.value = "btn-box2";
					console.log("连接");
				})
				navigator.hid.addEventListener("disconnect",(event)=>{
					console.log("HID disconnected: ", event.device);
                    //btnbox.value = "btn-box2";
                    showPage1.value = true;
                    showPage2.value = false;
                    showPage3.value = false;
                    updateflag.value = true;
                    progressvalflag.value = false;
                    gamepad_flash_main_version.value = null;
                    gamepad_flash_beta_version.value = null;
                    device.value = null;
                    Firmware_list.value = [];
                    ProgressVal.value = 0;
					console.log("连接断开");
				})
				// navigator.hid.onconnect = async (event) => {
				// 	console.log("HID connected: ", event.device);
                //     btnbox.value = "btn-box2";
                //     ConnectButton_Flag.value = true;
				// }
				// navigator.hid.ondisconnect = (event) => {
				// 	console.log("HID disconnected: ", event.device);
                //     btnbox.value = "btn-box1";
                //     showPage1.value = true;
                //     showPage2.value = false;
                //     showPage3.value = false;
                //     updateflag.value = true;
                //     progressvalflag.value = false;
                //     gamepad_flash_main_version.value = null;
                //     gamepad_flash_beta_version.value = null;
                //     device.value = null;
                //     Firmware_list.value = [];
                //     ProgressVal.value = 0;
                //     ConnectButton_Flag.value = false;
				// };
			}
        });  
//---------------------------------------------------mothed----------------------------------定义方法-------------替代mothed:{}--------
    // function myFunction(){ }
			//连接HID设备
			async function ConnectHidDevices() {
				device.value = await ConnectionHID();
				if (device.value != null) {
					//连接上后直接读取固件版本
					let gamepad_flash_original_version = await ReadGame_PadFlashVersion(device.value);
					this.gamepad_flash_main_version = (gamepad_flash_original_version & 0xffff) / 100; //低16位
					this.gamepad_flash_beta_version = (gamepad_flash_original_version >> 16) & 0xffff;
					//读取固件列表
					//console.log("读取固件列表");
					this.gamepad_PID = await ReadGamePadPid(device.value); //读取手柄pid
					let onfigContent = await GetType(); //获取所有固件类型
					let type = onfigContent.data.type[this.gamepad_PID];
					let Type_json = {
						type: type
					} //根据该参数获取后端固件信息
					let buffer = await GetSelect(Type_json).then(res => {
						return res.data;
					})
					this.Firmware_list = Array.from(buffer.data);
					//new显示界面2
					this.showPage1 = false;
					this.showPage2 = true;
					console.log(this.Firmware_list);
				}
			}

			async function selectFirmware(firmware, index) {
				id.value = firmware.id; //获取固件id
				filePathName.value = firmware.filePathname;
				let startIndex = filePathName.value.lastIndexOf('/') + 1;
				DownloadName.value = filePathName.value.slice(startIndex);
				radio.value = index;
			}

            async function startUpdate() {
				//console.log(DownloadName.value);
				await downloadFile(DownloadName.value); //下载固件
				//读取dat头数据，datPid与gamepadPid进行比对，匹配则允许更新操作
				firmware_header.value = await GetDatcontent(); //获取Dat头数据
				dat_PID.value = firmware_header.value.pid;
				gamepad_PID.value = await ReadGamePadPid(device.value);
				console.log(
					`GamepadPid is ${gamepad_PID.value.toString(16).toUpperCase()}；DatPid is ${dat_PID.value.toString(16).toUpperCase()}`
					);
				if (dat_PID.value != gamepad_PID.value) {
					Update.value = "固件与手柄设备不适配，无法更新";
					console.log(Update.value)
				} else {
					Update.value = "允许更新，开始更新"
					console.log(Update.value)
					//在此处添加更新方法
					//ui
					updateflag.value = false; //切换更新按钮
					progressvalflag.value = true; //进度条显示
					//用户画像
					let id_json = {
						id: id.value
					}
					PutUserAgent(id_json); //根据固件id修改用户画像
					StartUpdata(device.value, firmware_header.value, ProgressVal);
				}

			}

            function openDialog(item) {
				Version.value = item.version;
				dialogLog.value = item.readme.split("。").map(item => item.trim()); //句号分割
				dialogVisible.value = true;
				console.log("Dialog opened", dialogLog.value);
			}

            function openFaq() {
                FaqVisible.value = true;
            }
            function handleClick(tab, event) {
				console.log(tab, event);
			}

            function copyToClipboard(content) {
				// 处理字符串，去除多余的空格、换行和制表符
				const processedContent = content.split('\n') // 按行分割
					.map(line => line.replace(/\t/g, '    ').trim()) // 替换制表符为4个空格，并去除每行首尾空格
					.filter(line => line) // 过滤掉空行
					.join('\n'); // 重新组合成字符串
				if (!navigator.clipboard) {
					const textarea = document.createElement('textarea');
					textarea.value = processedContent; // 使用处理后的内容
					document.body.appendChild(textarea);
					textarea.select();
					try {
						const successful = document.execCommand('copy');
						if (successful) {
							showNotification('Cpoy','复制成功','success');
						} else {
							showNotification('Cpoy','复制失败','error');
						}
					} catch (err) {
						showNotification('Cpoy','复制失败','error');
					}
					document.body.removeChild(textarea);
				} else {
					navigator.clipboard.writeText(processedContent).then(() => {
						showNotification('Cpoy','复制成功','success');
					}).catch(err => {
						showNotification('Cpoy','复制失败','error');
					});
				}
			}




//---------------------------------------------------return-------------------------------------返回响应式数据和方法，以便在模板中使用----return 替代vue2的data()--
        return {
        // 响应式数据
            device,
            gamepad_PID,
            Firmware_list,
            filePathName,
            DownloadName,
            firmware_header,
            dat_PID,
            id,
            //UI
            Update,
            gamepad_flash_main_version,
            gamepad_flash_beta_version,
            ProgressVal,
            //"连接"按钮使能
            ConnectButton_Flag,
            //页面显示
            showPage1,
            showPage2,
            showPage3,
            btnbox,
            //弹窗
            Version,
            dialogLog,
            dialogVisible,
            //FAQ弹窗
            activeName,
            FaqVisible,
            //列表项1默认展开0-2
            activeNames,
            //允许更新 flag
            updateflag,
            progressvalflag,
            //固件索引
            radio,
            //一键赋值内容
            linuxCommand1,
            linuxCommand2,
        //方法
            ConnectHidDevices,
            selectFirmware,
            startUpdate,
            openDialog,
            openFaq,
            handleClick,
            copyToClipboard,
        }


    },

}
</script>


<template>
	<div class="bgcolor-box" id="app">
		<!-- 第一个页面内容 -->
		<div v-if="showPage1" class="page1">
			<p>请通过 USB 连接八位堂设备</p>
			<!-- 连接动画 -->
			<div class="animation">
				<div class="arrow">
					<i class="fa fa-angle-up fa-3x"></i>
					<i class="fa fa-angle-up fa-3x"></i>
					<i class="fa fa-angle-up fa-3x"></i>
				</div>
				<img src="@/assets/images/usb.svg" class="usb" />
			</div>
			<!-- 按钮第一/第二个状态btn-box1，btn-box2 -->
            <button :class="btnbox" @click="ConnectHidDevices()" :disabled=!ConnectButton_Flag>
                <span>连接</span>
            </button>
			<div class="faq" @click.stop="openFaq()">
				<p>常见问题</p>
			</div>
		</div>
		<!-- 第二个页面内容 -->
		<div v-if="showPage2" class="page2">
			<div class="product">
				<div class="icon">
					<img src="@/assets/images/10th.gif" />
					<!-- <img src="@/assets/images/ultimate-controller.svg" /> -->
				</div>
				<div class="name">
					<h1>八位堂猎户座蓝牙手柄</h1>
					<p>当前固件版本 V{{gamepad_flash_main_version}} beta:{{ gamepad_flash_beta_version }}</p>
				</div>
			</div>
			<!-- 日志列表 -->
			<div class="log">
				<el-radio-group v-model="radio">
					<ul>
						<li v-for="(item, index) in this.Firmware_list" :key="index"
							:class="{ selected: radio === index }" @click="selectFirmware(item,index)">
							<p>{{ item.fileName }} V{{ item.version }}</p>
							<div class="right-box">
								<span v-if="item.isNew">NEW</span>
								<div class="log-btn" @click.stop="openDialog(item)">
									<p>日志</p>
								</div>
								<el-radio :value="index" class="custom-radio"></el-radio>
							</div>
						</li>
					</ul>
				</el-radio-group>
			</div>
			<!-- 更新按钮的不同状态 -->
			<div class="renew">
				<div v-if="updateflag" class="renew-btn" @click="startUpdate()">
					<p>更新</p>
				</div>
				<div v-if="progressvalflag" class="renew-strip">
					<div class="demo-progress">
						<el-progress :text-inside="true" :stroke-width="24" color='rgb(56 129 165)'
							:percentage=Math.floor(ProgressVal) />
					</div>
					<span>升级过程中请勿退出浏览器或关闭计算机</span>
				</div>
				<div v-if=false class="renew-btn">
					<p>完成</p>
				</div>
			</div>
		</div>
		<div v-if="showPage3" class="page3">
			<p><i class="fa fa-warning fa-3x"></i></p>
			<p>您使用的浏览器不支持此工具，请使用以下最新桌面版浏览器访问：</p>
			<br />
			<p>* Google Chrome</p>
			<p>* Google Chromium</p>
			<p>* Microsoft Edge</p>
			<p>* Opera</p>
		</div>
		<!-- 日志弹窗 -->
		<el-dialog v-model="dialogVisible" width="650px" :close-on-click-modal="true" :show-close="false">
			<p class="text1">当前版本</p>
			<p class="text2">{{ gamepad_flash_main_version }}</p>
			<hr />
			<div class="log-box">
				<p v-for="(item, index) in this.dialogLog" :key="index" class="text3">{{ item }}</p>
			</div>
			<template #footer>
				<el-button @click="dialogVisible = false">确定</el-button>
			</template>
		</el-dialog>
		<!-- FAQ 弹窗 -->
		<el-dialog v-model="FaqVisible" width="650px" :close-on-click-modal="true" :show-close="false">
			<div class="faq-dialog">
				<el-tabs v-model="activeName" class="demo-tabs" @tab-click="handleClick">
					<el-tab-pane label="支持设备" name="first">
						<div class="support">
							<p>* 猎户座二代青春版有线手柄</p>
							<p>* 猎户座二代青春版无线手柄</p>
						</div>
					</el-tab-pane>
					<el-tab-pane label="常见问题" name="second">
						<div class="faq-list">
							<el-collapse v-model="activeNames">
								<el-collapse-item title="支持哪些设备？">
									<div>请查看 [支持的设备列表]。</div>
								</el-collapse-item>
								<el-collapse-item title="我的浏览器并未在支持列表中，能否使用？">
									<div>通常情况下，基于 Chromium 内核的浏览器，并且支持 WebUSB API 功能的其他浏览器应该可以正常工作。</div>
								</el-collapse-item>
								<el-collapse-item title="未来是否会支持更多设备？">
									<div>请保持关注我们的社交媒体的相关更新信息。</div>
								</el-collapse-item>
								<el-collapse-item title="如果更新失败，能否重新更新？">
									<div>可以，请参照设备使用说明书，强制关机后重新打开，再次使用。</div>
								</el-collapse-item>
							</el-collapse>
						</div>
					</el-tab-pane>
					<el-tab-pane label="Linux 系统" name="third">
						<div class="faq-list">
							<el-collapse v-model="activeNames">
								<el-collapse-item title="我的 Linux 设备无法使用此工具？" name="1">
									<div>如果您的计算机运行基于 Linux 的操作系统，您可能需要添加新的 udev 规则，然后才能使用或更新控制器,请依照以下操作完成设置：</div>
									<div>1. 复制以下内容到终端运行以下命令：<el-button size="small"
											@click="copyToClipboard(linuxCommand1)">复制</el-button></div>
									<div>
										<pre><code>&#123; cat &lt;&lt;EOF</code></pre>
										<pre><code># Controller</code></pre>
										<pre><code>KERNEL=="hidraw*", ACTION=="add", ATTR&#123;manufacturer&#125;=="8BitDo", SUBSYSTEM=="hidraw", ATTRS&#123;idVendor&#125;=="2dc8", MODE="0666"</code></pre>
										<pre><code>SUBSYSTEMS=="usb", ATTRS&#123;idVendor&#125;=="2dc8", ATTRS&#123;idProduct&#125;=="310a|6100", MODE="0660", GROUP="plugdev", TAG+="uaccess"</code></pre>
										<pre><code>&#125; | sudo tee /etc/udev/rules.d/8-bitdo-controller.rules</code></pre>
									</div>
									<div>2. 再次运行以下命令：<el-button size="small"
											@click="copyToClipboard(linuxCommand2)">复制</el-button>
									</div>
									<div>
										<pre><code>sudo udevadm control --reload-rules && sudo udevadm trigger</code></pre>
									</div>
								</el-collapse-item>
							</el-collapse>
						</div>
					</el-tab-pane>
				</el-tabs>
			</div>
		</el-dialog>
	</div>
</template>
<style scoped>
	/* Base style */
	@import url('https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css');
	@import url('@/assets/css/reset.css');
	/* Page style */
	@import url('@/assets/css/Web-Upgrade.css');
</style>