//gamepad_flash_Address
export const CY_PAGE_SIZE = 0x1000
const Address_buffer = new ArrayBuffer(8);          //装两个Uint8类型数据
let AddressView = new DataView(Address_buffer)
AddressView.setUint32(0, 0x01000000, true);
AddressView.setUint32(4, 0x18000, true);
export const CY_NVIC_VectTab_FLASH = AddressView.getUint32(0, true)                //(UINT32)0x01000000
export const CY_APPLICATION_ADDRESS_OFFSET = AddressView.getUint32(4, true)           //(UINT32)0x18000
export const CY_PRO_2_APP_ADDRESS = CY_NVIC_VectTab_FLASH+CY_APPLICATION_ADDRESS_OFFSET;
export const CY_PRO_2_ENCODE_ID_ADDRESS = CY_PRO_2_APP_ADDRESS-CY_PAGE_SIZE;
export const CY_PRO_2_BOOT_VERSION_ADDRESS = CY_PRO_2_ENCODE_ID_ADDRESS+32;
export const CY_PRO_2_APP_VERSION_FLASH_ADDRESS = CY_PRO_2_BOOT_VERSION_ADDRESS+32;


//PACKET
export const BOOT_CMD_PACKET = 0x05

//BooT_CMD
export const BOOT_ERASE_FLASH_CMD = 4
export const BOOT_RSP_OK_CMD = 0
export const BOOT_RESET_CMD = 7
export const BOOT_GET_DEVICE_PID = 8
export const BOOT_READ_DATA_CMD = 5
export const BOOT_INIT_DEVICE_CMD = 0xc1
export const BOOT_FLASH_CRC_CMD = 0xc3
export const BOOT_FLASH_SAVE_CMD = 0xc4
export const BOOT_ENCRYPT_WRITE_FLASH_CMD = 3

//Generate_BootCmdPacket()
export const boot_header_t_size = 16;

export const BOOT_REV_REPORT_ID = 0x00

//手柄数据
export const Sizeof_boot_header_t = 16
export const boot_header_t = {
    cmd:0,                  //UINT16   2
    cmd_params:0,           //UINT16   2
    len:0,                  //UINT16   2 The length for current sendding.
    crc:0,                  //UINT16   2
    total_len:0,            //UINT32   4 the offset for total length.
    offset:0                //UINT32   4 the offset for total length.
}
//复位
export function resetboot_header_t(){
    return {
        cmd:0,                  //UINT16   2
        cmd_params:0,           //UINT16   2
        len:0,                  //UINT16   2 The length for current sendding.
        crc:0,                  //UINT16   2
        total_len:0,            //UINT32   4 the offset for total length.
        offset:0                //UINT32   4 the offset for total length.
    }
}
//手柄用于接收结构体
export const Sizeof_receive_boot_header_t = 16
export const receive_boot_header_t = {
    cmd:0,                  //UINT16   2
    cmd_params:0,           //UINT16   2
    len:0,                  //UINT16   2 The length for current sendding.
    crc:0,                  //UINT16   2
    total_len:0,            //UINT32   4 the offset for total length.
    offset:0                //UINT32   4 the offset for total length.
}




//dat文件：FIRMWARE_HHEADER_T,固件头信息
export const Sizeof_FIRMWARE_HHEADER_T = 28;
export const FIRMWARE_HHEADER_T = {                     //都是unsigned int类型，4字节
    version:0,                                          //4
    desAddress:0,                                       //4
    desLen:0,                                           //4
    pid:0,
    reserved1:0,
    revision:0,
    reserved2:0,
}
//CRC校验用
export const sizeofBoot_crc_list = 12;
export const boot_crc_list = {
    address:0,                      //UINT32  4
    len:0,                          //UINT32  4
    block_size:0,                   //UINT32  4
}


//flash_info
export const sizeofFlash_info = 8
export const flash_info = {
    flie_length:0,                  //UINT32  4
    file_version:0,                 //UINT32  4
}

//存储dat数据
// export var DatBuffer = 0;

//用于完成更新操作，reset手柄后，自动打开原先打开的hid设备
// export const GamepadPID_VID={
//     pid:0,
//     vid:0,
// }




