import { createApp } from 'vue'
import App from './App.vue'
import ElementPlus from 'element-plus';
import 'element-plus/dist/index.css';
// import Antd from 'ant-design-vue';

// import 'ant-design-vue/dist/antd.css';

const app = createApp(App);

// 使用 Element Plus 插件
app.use(ElementPlus).mount('#app');


// app.use(Antd).mount('#app');