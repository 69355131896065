import axios from "axios";

//axios.defaults.baseURL = 'http://beta.8bitdo.com:8080'
//axios.defaults.baseURL = 'http://120.78.9.22:8083'   //测试使用,aliyun服务器
//axios.defaults.baseURL = 'https://120.78.9.22:8083'   //测试使用,aliyun服务器    https
//axios.defaults.baseURL = 'http://142.171.47.186:8083'   //xu
//axios.defaults.baseURL = 'http://localhost:8083'
//axios.defaults.baseURL = 'https://tools.zackxu.com:8083'
//-------------------20241011-----------------axios.defaults.baseURL = 'https://tools.zackxu.com'         //使用nginx代理转发

//axios.defaults.baseURL = 'http://66.42.103.86:8083'
axios.defaults.baseURL = 'https://web-tools.8bitdo.com'                     //使用nginx代理转发
// eslint-disable-next-line no-unused-vars
export const GetTypeAction = (url)=>{
    return axios({
        url:url,
        method:'GET',
    })
}
//用于获取下载固件
export const PostAction = (url,data)=>{
    return axios({
        url:url,
        method:'POST',
        data:data,
    })
}
//用户画像、获取下载url
export const PutAction = (url,data) =>{
    return axios({
        url:url,
        method:'PUT',
        data:data,
    })
}
